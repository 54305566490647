<template>
	<div class="GenderInfo">
		<el-link type="primary" :href="plink"></el-link>
		<el-link type="primary" :href="mlink"></el-link>
	</div>
</template>

<script>
export default {
	name: 'GenderInfo',
	data() {
		return {
			plink : '',
			mlink : ''
		}
	},
	mounted() {
		this.plink = '/#/genderman';
		this.mlink = '/#/genderwoman';
	}
}
</script>

<style scoped="scoped">
.GenderInfo{
	width: 100%;
	position: relative;
	height: 12rem;
	padding-top: 50%;
}
.GenderInfo .el-link{
	width: 50%;
	height: 12rem;
	float: left;
	padding: 0;
    margin: 0;
	background-size: 100% 100%;
	background-color: inherit;
	border: 0 none;
}
.GenderInfo .el-link:first-child{
	background-image: url(../../../../assets/images/gi_man.png);
}
.GenderInfo .el-link:last-child{
	background-image: url(../../../../assets/images/genderInfo_woman.png);
}
</style>
